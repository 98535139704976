import React from 'react'
import Text from '~/components/molecules/Text';
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Download({key, index, ...props}) {

  const section = props

  return (
    <FadeInWhenVisible>
      <section id={'download' + index} className="my-12 md:my-20 md:mt-12 lg:w-11/12 mx-auto">
        <div className="container bg-rose px-8 md:px-16 lg:px-28 py-12 flex flex-wrap md:flex-nowrap justify-between items-center space-y-8 md:space-y-0">
          <FadeInWhenVisible className="w-full sm:w-8/12 md:w-4/12">
            {section?.image && <Image data={section?.image}  />}
          </FadeInWhenVisible>
          <TextCard className="w-full md:w-7/12 lg:w-6/12" {...section.textCard} />
        </div>
      </section>
    </FadeInWhenVisible>
  )
}
